/* SetupAssistant.css */
.setup-container {
    text-align: center;
    padding: 50px 20px;
    font-family: sans-serif; /* Consistent with LandingPage */
  }
  
  .setup-header {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .setup-label {
    font-size: 1.2em;
    margin-bottom: 10px;
    display: block; /* Ensures the label is on a new line */
  }
  
  .setup-select {
    font-size: 1.1em;
    margin-bottom: 20px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .setup-button {
    font-size: 1.2em;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .setup-button:disabled {
    background-color: #cccccc;
  }

  .spinner {
    border: 4px solid rgba(0, 123, 255, 0.2); /* Light blue border */
    border-top: 4px solid #007bff; /* Darker blue border top */
    border-radius: 50%; /* Makes it circular */
    width: 20px; /* Size of the spinner */
    height: 20px; /* Size of the spinner */
    animation: spin 1s linear infinite; /* Animation for the spinner */
    display: inline-block; /* Allows it to sit next to text */
    vertical-align: middle; /* Aligns it with the text */
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .avatar-image {
    width: 100px;
    height: 100px;
    border-radius: 50%; /* Makes the avatar image circular */
    margin-top: 20px;
  }
  
  /* Responsive design adjustments */
  @media (max-width: 600px) {
    .setup-header {
      font-size: 1.5em;
    }
  
    .setup-label,
    .setup-select,
    .setup-button {
      font-size: 1em;
    }
  }
  
