/* MainApp.css */

.app-container {
  text-align: center;
  padding: 20px;
  font-family: sans-serif;
}

.avatar-image {
  max-width: 50px;  /* Set a maximum width */
  max-height: 50px; /* Set a maximum height to maintain aspect ratio */
  border-radius: 50%;
  margin-bottom: 10px;
  display: block; /* Ensure it's a block element */
  margin-left: auto; /* Center align */
  margin-right: auto;
}

.error-message {
  color: red;
  margin: 10px 0;
}

.button {
  font-size: 1.2em;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.button:disabled {
  background-color: #cccccc;
}

.select {
  font-size: 1.1em;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.section h3 {
  margin-bottom: 10px;
}

.section p {
  font-size: 1.1em;
}

.input-file {
  margin-bottom: 20px;
}

/* Additional Styles for MainApp.js */
.voice-recording-section, .image-upload-section, .voice-selection-section {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.voice-recording-section p, .image-upload-section p {
  font-size: 1em;
  margin: 10px 0;
}

/* Responsive design adjustments */
@media (max-width: 600px) {
  .button, .select {
    font-size: 1em;
  }

  .section h3 {
    font-size: 1.2em;
  }

  .section p {
    font-size: 1em;
  }
}
