/* LandingPage.css */
.container {
    text-align: center;
    padding: 50px 20px;
    font-family: sans-serif;
  }
  
  .header {
    font-size: 2em; /* Larger font for the header */
    margin-bottom: 20px;
  }
  
  .paragraph {
    font-size: 1.2em; /* Slightly larger font for readability */
    margin-bottom: 20px;
  }
  
  .link {
    font-size: 1.2em;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-decoration: none;
  }
  
  /* Responsive design adjustments */
  @media (max-width: 600px) {
    .header {
      font-size: 1.5em;
    }
  
    .paragraph {
      font-size: 1em;
    }
  
    .link {
      font-size: 1em;
      padding: 8px 16px;
    }
  }
  